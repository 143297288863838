import React, { useState } from 'react';
import '../styles/header.css';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <img src="/logoedupai.png" alt="Decussi Advogados" />
        </div>
        {/* Ícone do menu hamburguer */}
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        {/* Menu de navegação padrão */}
        <div className="div_navbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                href="#home"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('home');
                }}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#sobre"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('sobre');
                }}
              >
                Sobre
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#atuacao"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('atuacao');
                }}
              >
                Atuação
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#depoimentos"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('depoimentos');
                }}
              >
                Depoimentos
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#contato"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('contato');
                }}
              >
                Contato
              </a>
            </li>
          </ul>
        </div>
        {/* Menu mobile que cobre a tela toda */}
        <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
          <span className="close-btn" onClick={toggleMenu}>&times;</span>
          <ul>
            <li>
              <a
                href="#home"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('home');
                  toggleMenu();
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#sobre"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('sobre');
                  toggleMenu();
                }}
              >
                Sobre
              </a>
            </li>
            <li>
              <a
                href="#atuacao"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('atuacao');
                  toggleMenu();
                }}
              >
                Serviços
              </a>
            </li>
            
            <li>
              <a
                href="#depoimentos"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('depoimentos');
                  toggleMenu();
                }}
              >
                Depoimentos
              </a>
            </li>
            <li>
              <a
                href="#contato"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection('contato');
                  toggleMenu();
                }}
              >
                Contato
              </a>
            </li>
          </ul>
        </div>
        <a
          href='https://api.whatsapp.com/send/?phone=5547991063538&text=Ol%C3%A1%2C+gostaria+de+uma+consultoria+sobre+a+advocacia%21&type=phone_number&app_absent=0'
          className='contato'
        >
          Contato
        </a>
      </div>
    </header>
  );
};

export default Header;
