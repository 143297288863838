import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles/depoimentos.css';

// Import required modules
import { Pagination, Navigation } from 'swiper/modules';

function Depoimentos() {
  const testimonials = [
    {
      text: "Profissionalismo e dedicação são apenas duas das muitas qualidades que encontro em Eduardo. Sua habilidade de comunicar com clareza e sua firme dedicação aos casos que assume são verdadeiramente impressionantes.",
      author: "Nicolas Almeida"
    },
    {
      text: "O trabalho com a equipe de Eduardo foi incrível, sempre muito prestativo e atencioso em todas as fases do projeto. Certamente recomendo.",
      author: "Julia Pereira"
    },
    {
      text: "Eduardo possui uma abordagem inovadora e criativa. Seu compromisso com a excelência reflete diretamente no sucesso dos projetos.",
      author: "Carlos Santana"
    },
    {
        text: "Eduardo é um advogado excepcional, sempre comprometido em oferecer o melhor serviço aos seus clientes. Sua capacidade de análise e sua determinação em buscar a justiça são inspiradoras.",
        author: "Claudia K."
      }
  ];

  return (
    <section className="section_depoimentos" id='depoimentos'>
      <h2 className='depoimentos_h2'>Depoimentos</h2>
      <div className="swiper-container">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <p>{testimonial.text}</p>
                <h4>{testimonial.author}</h4>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default Depoimentos;
