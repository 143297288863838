import '../styles/sobre.css';
import { useEffect, useState, useRef } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

function Atuacao() {
    // Função para o contador
    const useCounter = (start, end, duration, trigger) => {
        const [count, setCount] = useState(start);

        useEffect(() => {
            if (!trigger) return; // Somente inicia o contador quando o trigger for verdadeiro

            let startTime = null;
            const step = (timestamp) => {
                if (!startTime) startTime = timestamp;
                const progress = Math.min((timestamp - startTime) / duration, 1);
                setCount(Math.floor(progress * (end - start) + start));
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }, [start, end, duration, trigger]);

        return count;
    };

    // Estado para saber se a seção está visível
    const [isVisible, setIsVisible] = useState(false);

    // Referência para a seção dos contadores
    const countersRef = useRef(null);

    // Usar IntersectionObserver para detectar quando a seção está visível
    useEffect(() => {
        const currentRef = countersRef.current; // Armazena a referência atual em uma variável local

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Desconectar o observer após a primeira visualização
                }
            },
            { threshold: 0.1 } // Ativa o contador quando 10% da seção estiver visível
        );

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef); // Usa a variável local para desobservar
            }
        };
    }, []);

    const consultations = useCounter(0, 1000, 2000, isVisible);
    const notes = useCounter(0, 30, 2000, isVisible);
    const years = useCounter(0, 15, 2000, isVisible);
    const hours = useCounter(0, 30, 2000, isVisible);

    return (
        <>
            <section className="section_sobre" id='sobre'>
                <div className="sobre-content">
                    <div className="sobre-image">
                        <img src="/fotoedupai.png" alt="Eduardo Decussi do Nascimento" />
                    </div>
                    <div className="sobre-text">
                        <h1 className="title">Nossa Promessa?</h1>
                        <div className="commitment">
                            <h3 className="commitment-title">Compromisso com os clientes</h3>
                            <p>Obter sucesso oferecendo o melhor atendimento ao nosso cliente, com honestidade, segurança e transparência, são os alicerces que orientam a execução cotidiana de nossos serviços. Conhecer profundamente os clientes e seus problemas são essenciais para a obtenção do êxito almejado.</p>
                        </div>
                        <div className="commitment">
                            <h3 className="commitment-title">Compromisso com a sociedade</h3>
                            <p>Valorizar pessoas, promover esforços em torno de causas comuns e investir no cultivo do sentido de justiça são atributos reforçados diariamente comigo Eduardo Decussi, Assessoria e Consultoria Jurídica. Acreditamos que devemos muito do que construímos até hoje à sociedade.</p>
                        </div>
                        <p className="signature">Eduardo Decussi do Nascimento</p>
                        <p className="oab">OAB/SC 41.487</p>
                    </div>
                </div>
            </section>

            {/* Nova seção com contadores */}
            <section className="counters-section" ref={countersRef}>
                <div className="counter-container">
                    <div className="counter-item">
                        <i className="bi bi-briefcase"></i>
                        <p>+{consultations}</p>
                        <p>Consultas Respondidas</p>
                    </div>
                    <div className="counter-item">
                        <i className="bi bi-book"></i>
                        <p>+{notes}</p>
                        <p>Notas Técnicas</p>
                    </div>
                    <div className="counter-item">
                        <i className="bi bi-award"></i> {/* Ícone de prêmio ou conquista */}
                        <p>+{years}</p>
                        <p>Anos de Prática Jurídica</p>
                    </div>
                    <div className="counter-item">
                        <i className="bi bi-people"></i>
                        <p>+{hours}</p>
                        <p>Horas Palestradas</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Atuacao;
