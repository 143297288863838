import '../styles/atuacao.css';
import { Row, Col } from 'react-bootstrap';
import { SuitcaseFill, HouseDoorFill, CurrencyDollar, FileTextFill, PeopleFill, CarFrontFill } from 'react-bootstrap-icons';
import { useEffect, useRef, useState } from 'react';

function Atuacao() {
  // Estado para controlar quando a seção está visível
  const [isVisible, setIsVisible] = useState(false);

  // Referência para a seção
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentRef = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Desconectar para evitar a execução múltipla
        }
      },
      { threshold: 0.4 } // Inicia quando 10% da seção está visível
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section className="atuacao_section" ref={sectionRef} id='atuacao'>
      <div className="container_atuacao text-center">
        <h2 className="atuacao_titulo">Áreas de Atuação</h2>
        <div className="atuacao_balanca">
          <hr className="atuacao_linha" />
          <i className="atuacao_balanca_icone"></i>
          <hr className="atuacao_linha" />
        </div>
        {/* Carrega o conteúdo somente quando a seção estiver visível */}
        {isVisible && (
          <Row className="atuacao_row">
            <Col sm={12} md={4} className="atuacao_card">
              <div className="atuacao_card_content">
                <SuitcaseFill className="atuacao_icon" />
                <h3 className="atuacao_card_title">Empresarial</h3>
                <p className="atuacao_card_text">
                  Cobrança extrajudicial e judicial, contrato social, elaboração e revisão de contratos diversos, estatuto social, planejamento sucessório e proteção patrimonial.
                </p>
              </div>
            </Col>
            <Col sm={12} md={4} className="atuacao_card">
              <div className="atuacao_card_content">
                <HouseDoorFill className="atuacao_icon" />
                <h3 className="atuacao_card_title">Imobiliário</h3>
                <p className="atuacao_card_text">
                  Aluguel, compra e venda, condomínio, contratos, desmembramento, escrituras, promessa de compra e venda, entre outros.
                </p>
              </div>
            </Col>
            <Col sm={12} md={4} className="atuacao_card">
              <div className="atuacao_card_content">
                <CurrencyDollar className="atuacao_icon" />
                <h3 className="atuacao_card_title">Tributário</h3>
                <p className="atuacao_card_text">
                  Contencioso administrativo e judicial, consulta perante órgãos públicos, mandado de segurança e restituição de tributos pagos.
                </p>
              </div>
            </Col>
            <Col sm={12} md={4} className="atuacao_card">
              <div className="atuacao_card_content">
                <FileTextFill className="atuacao_icon" />
                <h3 className="atuacao_card_title">Serviços Especializados</h3>
                <p className="atuacao_card_text">
                  Cobrança extrajudicial e judicial, contrato social, elaboração e revisão de contratos diversos, estatuto social, planejamento sucessório e proteção patrimonial.
                </p>
              </div>
            </Col>
            <Col sm={12} md={4} className="atuacao_card">
              <div className="atuacao_card_content">
                <PeopleFill className="atuacao_icon" />
                <h3 className="atuacao_card_title">Família e Sucessões</h3>
                <p className="atuacao_card_text">
                  Adoção, alimentos, bens de família, casamento, pacto antenupcial, inventário e outros.
                </p>
              </div>
            </Col>
            <Col sm={12} md={4} className="atuacao_card">
              <div className="atuacao_card_content">
                <CarFrontFill className="atuacao_icon" />
                <h3 className="atuacao_card_title">Cível, Previdenciário e Trânsito</h3>
                <p className="atuacao_card_text">
                  Aposentadoria, pensão por morte, defesa de autuação, responsabilidade civil e outros.
                </p>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </section>
  );
}

export default Atuacao;
