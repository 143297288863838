import React from 'react';
import '../styles/contato.css';

function Contato() {
  // Função para rolar suavemente até o topo


  return (
    <section className="section_contato" id="contato">
      <h1 className='h1_contato'>Contatos</h1>
      <div className="container_contato">
        <div className="left_section">
          <div className='div_redes_sociais'>
            <a href='https://www.instagram.com/eduardodecussi/' className='rede_contato'><i className="bi bi-instagram"></i></a>
            <a href='https://www.facebook.com/eduardo.decussi.9?locale=pt_BR' className='rede_contato'><i className="bi bi-facebook"></i></a>
          </div>
          <div className="endereco">
            <h2>Endereço</h2>
            <p>701 R. XV de Novembro<br />Blumenau, Santa Catarina, Brasil</p>
          </div>
          <div className="contato_info">
            <h2>Contatos</h2>
            <p>+55 (47) 99106-3538<br />decussi.advocacia@gmail.com</p>
          </div>
        </div>
        <div className="right_section">
          <iframe
            className="google_map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.917444061448!2d-49.07172748447653!3d-26.918643683116376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94df1d7952f648a5%3A0x85bdf5f7ff6c9253!2sR.%20XV%20de%20Novembro%2C%20701%20-%20Centro%2C%20Blumenau%20-%20SC%2C%2089001-000%2C%20Brazil!5e0!3m2!1sen!2sus!4v1632949555401!5m2!1sen!2sus"
            allowFullScreen=""
            loading="lazy"
            title="Google Maps - Endereço"
          />
          {/* Botão para rolar até o topo */}
        </div>
      </div>
      <hr className='hr_contato' />
      <p className="copyright">© 2024 <a href='https://www.instagram.com/decussi_webdesign/' className='link_insta'>@decussi_webdesign</a>. Todos os direitos reservados.</p>
    </section>
  );
}

export default Contato;
