import Header from './components/header';
import Home from './components/home';
import CardSection from './components/CardSection';
import Sobre from './components/sobre';
import Atuacao from './components/atuacao';
import Depoimentos from './components/depoimentos';
import Contato from './components/contato';


function App() {
  return (
    <>
    <Header />
    <Home />
    <CardSection />
    <Sobre />
    <Atuacao />
    <Depoimentos />
    <Contato />
    </>
  );
}

export default App;
