import '../styles/home.css';

function Home() {
  return (
    <div className="home-container" id='home'>
      {/* Camada de sobreposição escura */}
      <div className="overlay"></div>
      <div className="content">
        <h1>Assessoria e Consultoria Jurídica</h1>
        <h2>Dr. Eduardo Decussi do Nascimento</h2>
        <p>"A essência da justiça emerge internamente e se materializa na audácia de nossos atos"</p>
      </div>
      {/* Vídeo de fundo */}
      <video autoPlay loop muted playsInline className="background-video">
        <source src="/3929683-hd_1920_1080_30fps.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default Home;
