import '../styles/cardSection.css';

const CardSection = () => {
  return (
    <div className="card-section">
      <h2>Precisando de auxílio jurídico?</h2>
      <p>Temos uma equipe especializada esperando para te auxiliar!</p>
      <a href='https://api.whatsapp.com/send/?phone=5547991063538&text=Ol%C3%A1%2C+gostaria+de+uma+consultoria+sobre+a+advocacia%21&type=phone_number&app_absent=0' className="button">Atendimento Online</a>
    </div>
  );
};

export default CardSection;
